import { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { AppCookie } from 'utils/cookies'
import { setRandomString } from 'utils/misc'

/**
 * hook used for logging out,
 * clearing cache and redirecting
 * to hydra for session reset
 */
interface IUseLogout {
  /**
   * Delay before logging out,
   * useful for showing loading screen
   */
  delay?: number
  /**
   * Redirect url to hydra,
   * or can just use env var:
   * NEXT_PUBLIC_HYDRA_URL
   */
  hydraUrl?: string
  /** param to direct back after logout */
  redirectUri?: string
  /** function to delete app specific cookies */
  deleteCookies: () => void
  /* id token for specific app */
  idToken: AppCookie
  /* if is impersonation, remove some real-case treatments */
  isImpersonation?: boolean
}

export const deleteCookiesAdmin = () => {
  Cookies.remove(AppCookie.TokenAdmin)
  Cookies.remove(AppCookie.RefreshAdmin)
}

export const deleteCookiesPartner = () => {
  Cookies.remove(AppCookie.TokenPartner)
  Cookies.remove(AppCookie.RefreshPartner)
  Cookies.remove(AppCookie.PartnerId)
  Cookies.remove(AppCookie.Impersonating)
}

export const deleteAllCookies = () => {
  Object.values(AppCookie).map(cookie => {
    Cookies.remove(cookie)
  })
}

export const deleteCookiesCustomer = () => {
  Cookies.remove(AppCookie.TokenCustomer)
  Cookies.remove(AppCookie.RefreshCustomer)
  Cookies.remove(AppCookie.CustomerId)
  Cookies.remove(AppCookie.Impersonating)
}

export const useClearCache = (deleteCookies: () => void) => {
  const client = useApolloClient()

  useEffect(() => {
    client.cache.reset()
    localStorage.clear()
    deleteCookies()
  }, [])
}

export const useRedirectByState = () => {
  const {
    query: { state },
    push
  } = useRouter()
  const redirectUri = (state as string)?.split('-')[1]
  if (redirectUri) {
    push(redirectUri)
  }
}

export const useLogout = ({
  delay = 1000,
  hydraUrl,
  redirectUri,
  deleteCookies,
  idToken,
  isImpersonation = false
}: IUseLogout) => {
  const client = useApolloClient()
  const token = Cookies.get(idToken)

  useEffect(() => {
    const logoutTimeout = setTimeout(async () => {
      // redirect to custom url after going to /expired page when logout
      // this is used on /session-conflict page from payments app
      const postExpiredRedirectUri = window.location.search
        ?.split('post_expired_redirect_to=')[1]
        ?.split('&')[0]

      // clean out cache and session token
      await client.resetStore()
      localStorage.clear()
      deleteCookies()

      if (isImpersonation) {
        return
      }

      const url = `${
        hydraUrl || process.env.NEXT_PUBLIC_HYDRA_URL
      }/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${setRandomString()}${
        postExpiredRedirectUri ? `-${postExpiredRedirectUri}` : ''
      }`

      // redirect to hydra logout
      window.location.href = url
    }, delay)

    return () => clearTimeout(logoutTimeout)
  }, [token])
}
